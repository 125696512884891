








import { Component, Vue } from "vue-property-decorator";
import ComponentCarousel from "../components/base/ComponentCarousel.vue";

@Component({
  components: {
    ComponentCarousel,
  },
})
export default class SignUpB extends Vue {}
